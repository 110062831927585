.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.image_carousel {
  display: block;
  max-width: 300px;
  max-height: 400px;
  width: auto;
  height: auto;
}

.variation-btn {
  font-size: 0.8em;
}

.container-main {
  max-width: 500px;
}

.wwwText {
  font-size: 0.9em;
  font-family: 'Montserrat', sans-serif;
}

.whyDiv {
  margin: 10px;
  width: 150px;
  padding: 10px;
}

.whyImg {
  height: 50px;
  margin-bottom: 5px;
}



.how_h1 {
  font-size: 2.0em;
}

.how_h2 {
  font-size: 1.0em;
}

.how_txt {
  font-size: 0.8em;
  height: 50px;
  width: 160px;
  padding: 5px;
  color: #fff;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 0px 15px 15px 0px
}

.how_1 {
  width: 100px;
}

.footer {
  background-color: #DF9656;
  padding: 10px 0px;
  text-align: center;
  color: #fff;

}

.accordion-button:not(.collapsed) {
  color: #000000;
  background-color: #F8E5CC;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);

}